import React from 'react';
import '../../App.css';
import Cards from '../Cards';
//Import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Header from '../Header';
import Newtours from '../Newtours';
//import Carrusel from '../Carrusel'
import Tittle from '../Tittle'
function Home() {
  return (
    <>
      <Header/>
    <Tittle/>
      <Cards />
      <Newtours/>
      <Footer />
    </>
  );
}

export default Home;
