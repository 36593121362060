import React from 'react';
import { Helmet } from 'react-helmet';

function Header() {
  const title = "Discovery Adventure Costa Rica";
  const description = "Agencia de viajes especializada en aventuras en Costa Rica.";
  const keywords = "Agencia, Discovery Adventure Costa Rica";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Otros metadatos si es necesario */}
    </Helmet>
  );
}

export default Header;
