import React from 'react';

function Sitemap() {
  const pages = [
    { url: 'https://discoveryadventurecr.com/', lastmod: '2024-05-02T20:57:44+00:00' },
    { url: 'https://www.facebook.com/goridecr/' }
    // Agrega más URLs aquí si es necesario
  ];

  const sitemapXml = `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${pages.map(page => `
        <url>
          <loc>${page.url}</loc>
          ${page.lastmod ? `<lastmod>${page.lastmod}</lastmod>` : ''}
        </url>
      `).join('')}
    </urlset>
  `;

  return (
    <pre>{sitemapXml}</pre>
  );
}

export default Sitemap;
